.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
}

.services {
  padding: 50px 20px;
  background-color: #fff;
  text-align: center;
}

.services h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.service_cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.service_card {
  background-color: #f8f8f8;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}

.service_card h3 {
  font-size: 1.3rem;
  color: #333;
}

.service_card .icon {
  font-size: 5rem;
  color: #21867a;
}

.service_card ul {
  font-size: 0.9rem;
  text-align: left;
  list-style: disc;
  margin-block: 10px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .container {
      width: 100%;
  }
}
