.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px 50px 20px;
  background-color: #f8f8f8;
  scroll-margin-top: 50px;
}

.hero_content {
  max-width: 55%;
}

.hero h1 {
  font-size: 2.5rem;
  color: #2d3e50;
}

.hero p {
  font-size: 1rem;
  color: #555;
  margin: 20px 0;
}

.get_started_button {
  background-color: #2a9d8f;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.get_started_button:hover {
  background-color: #21867a;
}

.hero_image {
  position: relative;
  width: 40%;
  display: flex;
  justify-content: center;
  margin-block: 20px;
}

.hero_image img {
  max-width: 70%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
}

.hero_image_text {
  display: flex;
  position: absolute;
  top: 40px;
  left: -40px;
  background: rgba(224, 224, 224, 0.7);
  padding: 10px;
  border-radius: 5px;
  max-width: 65%;
}

.hero_image_text .icon {
  color: #21867a;
  margin-right: 10px;
  width: 40px;
}

.hero_image_text p {
  font-size: 0.9rem;
  color: #333;
  margin: 0;
}

@media (max-width: 768px) {
  .container {
      width: 100%;
  }

  .hero {
      flex-direction: column;
      margin-top: 30px;
      padding-top: 30px;
  }

  .hero_content {
      max-width: 100%;
  }

  .hero_image {
      width: 100%;
      margin-block: 60px;
  }

  .hero_image img {
      max-width: 100%;
  }
  
  .hero_image_text {
      left: 10px;
  }
}
